<template>
  <div class="behavior">
    <!-- 面包屑 -->
    <navi-gation />
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('DeviceManage.Auditing.Telephone')" name="1">
        <phone-tab :key="timer1" v-if="isChildUpdate1"></phone-tab>
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.Auditing.Textmessage')" name="2">
        <note-tab :key="timer2" v-if="isChildUpdate2"></note-tab>
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.Auditing.Chatmessage')" name="3">
        <chat-tab :key="timer3" v-if="isChildUpdate3"></chat-tab>
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.Auditing.Networkaccess')" name="4">
        <browser-tab :key="timer4" v-if="isChildUpdate4"></browser-tab>
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.Auditing.Inputinformation')" name="5">
        <input-tab  :key="timer5" v-if="isChildUpdate5"></input-tab>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import naviGation from '@/components/hearder/index' //面包屑
import pagination from '@/components/pagination/page.vue' //分页
import chatTab from './components/comptroller/chat_tab.vue'
import noteTab from './components/comptroller/note_tab.vue'
import phoneTab from './components/comptroller/phone_tab.vue'
import browserTab from './components/comptroller/browser_tab.vue'
import inputTab from './components/comptroller/input_tab.vue'
export default {
  components: {
    naviGation,
    pagination,
    phoneTab,
    noteTab,
    chatTab,
    browserTab,
    inputTab,
  },
  data() {
    return {
      activeName: '1',
      timer1: '',
      timer2: '',
      timer3: '',
      timer4: '',
      timer5: '',
      isChildUpdate1: true,
      isChildUpdate2: false,
      isChildUpdate3: false,
      isChildUpdate4: false,
      isChildUpdate5: false,
    }
  },
  mounted() {
    // 等于true 为敏感词
    if(this.$route.query.activeName == true){
      this.activeName = '5'
      this.isChildUpdate1 = false
      this.isChildUpdate2 = false
      this.isChildUpdate3 = false
      this.isChildUpdate4 = false
      this.isChildUpdate5 = true
    }
    // 等于false 为用户网络访问黑名单
    if(this.$route.query.activeName == false){
      this.activeName = '4'
      this.isChildUpdate1 = false
      this.isChildUpdate2 = false
      this.isChildUpdate3 = false
      this.isChildUpdate4 = true
      this.isChildUpdate5 = false
    }
  },
  methods: {
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
        this.isChildUpdate1 = true
        this.isChildUpdate2 = false
        this.isChildUpdate3 = false
        this.isChildUpdate4 = false
        this.isChildUpdate5 = false
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
        this.isChildUpdate1 = false
        this.isChildUpdate2 = true
        this.isChildUpdate3 = false
        this.isChildUpdate4 = false
        this.isChildUpdate5 = false
      } else if (name == 3) {
        this.timer3 = new Date().getTime()
        this.isChildUpdate1 = false
        this.isChildUpdate2 = false
        this.isChildUpdate3 = true
        this.isChildUpdate4 = false
        this.isChildUpdate5 = false
      } else if (name == 4) {
        this.timer4 = new Date().getTime()
        this.isChildUpdate1 = false
        this.isChildUpdate2 = false
        this.isChildUpdate3 = false
        this.isChildUpdate4 = true
        this.isChildUpdate5 = false
      }else if (name == 5) {
        this.timer5 = new Date().getTime()
        this.isChildUpdate1 = false
        this.isChildUpdate2 = false
        this.isChildUpdate3 = false
        this.isChildUpdate4 = false
        this.isChildUpdate5 = true
        }
    }
  }
}
</script>

<style lang='scss' scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
</style>
