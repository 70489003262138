<template>
  <div class="chat">
    <el-row :gutter="4" class="mt15" style="display: flex; margin-left: 0px">
      <el-col :span="3">
        <el-date-picker :clearable="false" style="width: 100%" size="small" v-model="formInline.optionDate1" type="date" :placeholder="$t('DeviceManage.Auditing.SelectDate')" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" @change="changeDate">
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false" style="width: 100%" size="small" v-model="formInline.optionDate2" type="date" :placeholder="$t('DeviceManage.Auditing.SelectDate')" value-format="yyyy-MM-dd" :picker-options="pickerOptions1" @change="changeDate">
        </el-date-picker>
      </el-col>
      <!-- 聊天状态 -->
      <el-col :span="3">
        <el-select style="width: 100%" class="el-select1" v-model="formInline.status" :placeholder="$t('DeviceManage.Auditing.Pleaseselect')" size="small">
          <el-option v-for="item in chatStatus" :key="item.statusValue" :label="$t(`DeviceManage.Auditing.Inputin.chatStatus.${item.statusKey}`)" :value="item.statusValue">
          </el-option>
        </el-select>
      </el-col>
      <select-input :options="searchType" @selectSearchChange="selectSearchChange" @searchList="onSubmit"></select-input>
      <!-- <el-col :span="3">
        <el-select style="width: 100%" class="el-select1" v-model="formInline.value1" placeholder="请选择" size="small" @change="searchTypeChange">
          <el-option v-for="item in searchType" :key="item.value1" :label="item.label1" :value="item.value1">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input size="small" v-model.trim="formInline.searchContent" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit('ser')"></el-input>
      </el-col> -->
      <el-col :span="3">
        <!-- <el-button size="small" type="primary" @click="onSubmit('ser')"> 查询 </el-button> -->

        <el-button size="small" type="primary" @click="outReport">
          {{$t('public.Derive')}}
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="position: relative; margin-left: 2px; margin-right: 10px">
      <el-table size="mini" :data="tableData" stripe fit tooltip-effect="dark" class="sort_table" style="width: 100%" @sort-change="sort" :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle" :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }">
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('DeviceManage.Auditing.Chatmes.Appname')" prop="app_name">
          <template v-slot="{ row }">
            <span v-if="row.appname == ''"> {{$t('DeviceManage.Auditing.Phone.Unknown')}} </span>
            <span v-else>
              {{ row.appname }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.DeviceName')" prop="device_name">
          <template v-slot="{ row }">
            <span v-if="row.devicename == ''"> {{$t('DeviceManage.Auditing.Phone.Unknown')}} </span>
            <span v-else>
              {{ row.devicename }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.Username')" prop="login_name">
          <template v-slot="{ row }">
            <span v-if="row.loginname == ''"> {{$t('DeviceManage.Auditing.Phone.Unknown')}} </span>
            <span v-else>
              {{ row.loginname }}
            </span>

          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('public.Section')" prop="group_full_name">
          <template v-slot="{ row }">
            <span v-if="row.groupfullname == ''"> {{$t('DeviceManage.Auditing.Phone.Unknown')}} </span>
            <span v-else-if="row.groupfullname">{{row.groupfullname.substr(groupFullNameShowByIndex)}}</span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('DeviceManage.Auditing.Chatmes.Content')" prop="content">
          <template v-slot="{row}">
            <span v-if="row.content==''">
              {{$t('DeviceManage.Auditing.Phone.Unknown')}}
            </span>
            <span v-else>
              {{row.content}}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('DeviceManage.Auditing.Chatmes.Sensitivewords')" prop="words">
          <template v-slot="{ row }">
            <span v-if="row.words == ''"> / </span>
            <span v-else>
              {{ row.words }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('DeviceManage.Auditing.Chatmes.Lexicalname')" prop="sensitive_name">
          <template v-slot="{ row }">
            <span v-if="row.sensitivename == ''"> / </span>
            <span v-else>
              {{ row.sensitivename }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" width="120" :label="$t('DeviceManage.Auditing.Chatmes.Status')" prop="status">
          <template v-slot="{ row }">
            <span v-if="row.status == '0'"> {{$t('DeviceManage.Auditing.Chatmes.Permit')}} </span>
            <span v-if="row.status == '1'"> {{$t('DeviceManage.Auditing.Chatmes.Prohibit')}} </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('DeviceManage.Auditing.Chatmes.Time')" prop="create_time">
          <template v-slot="{ row }">
            <span v-if="row.createtime == ''"> / </span>
            <span v-else>
              {{ row.createtime }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
      </div>
    </el-row>
  </div>
</template>

<script>
import selectInput from "@/components/selectInput"
import pagination from '@/components/pagination/page.vue' //分页
import ThreatDefinitionVue from '../../../appmanagement/PolicyConfig/ThreatDefinition.vue'
export default {
  components: {
    pagination,
    selectInput
  },
  data() {
    return {
      groupFullNameShowByIndex: '', //用户部门截取的下标
      pickerOptions1: {},
      pickerOptions0: {},
      // 表单控件
      formInline: {
        optionDate1: '', // 选择开始日期
        optionDate2: '', // 选择结束日期
        value1: 'appName', //默认选中设备名称
        status: '', //聊天状态
        info: '', //聊天信息类型
        send: '', //聊天发送类型
        searchContent: '', // 查询关键字
        sortStr: 'time desc' //排序
      },
      tableData: [], //表格数据
      // 分页
      currentPage: '',
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1,
        orderColume: 'create_time', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      //聊天状态
      chatStatus: [
        {
          statusKey: '状态',
          statusValue: ''
        },
        {
          statusKey: '允许',
          statusValue: '0'
        },
        {
          statusKey: '禁止',
          statusValue: '1'
        }
      ],
      selectVal:{
        deviceName:'',
        InputText:""
      },
      //查询类别
      searchType: [
        {
          value: 'appName',
          label: '应用名称'
        },
        {
          value: 'deviceName',
          label: '设备名称'
        },
        {
          value: 'loginName',
          label: '用户名'
        },
        {
          value: 'words',
          label: '敏感词'
        },
        {
          value: 'sensitiveName',
          label: '词库名称'
        }
      ],

      placeholder: '应用名称'
    }
  },
  created() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.formInline.optionDate1 = this.$common.getAnyMonth("-", -1);
    this.formInline.optionDate2 = this.$common.getDate();
    this.getTableList(this.selectVal); //获取表格数据
  },
  methods: {
    selectSearchChange(){

    },
    //日期控件
    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime()
      let end = new Date(this.formInline.optionDate2).getTime()
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != '') {
            return time.getTime() > Date.now() || time.getTime() > end
          }
          return time.getTime() > Date.now()
        }
      }
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          )
        }
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page
      this.page.pageSize = limit
      if (this.$route.query.activeName == false) {
      this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
      this.formInline.value = "1";
      this.formInline.value1 = "url";
      this.formInline.searchContent = this.$route.query.name;
    } else {
      this.formInline.optionDate1 = this.$common.getAnyMonth("-", -1);
      this.formInline.optionDate2 = this.$common.getDate();
      this.getTableList(this.selectVal); //获取表格数据
    }
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    },
    // 排序@sort-change="sort"
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order === 'ascending') {
        this.page.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.page.orderRule = 'DESC'
      }
      this.page.orderColume = column.prop
      this.onSubmit() //再次调用查询接口
    },
    //下拉选中查询类型--转译input中placeholder显示
    searchTypeChange(item) {
      this.searchType.forEach((k) => {
        if (item == k.value1) {
          this.placeholder = k.label1
        }
      })
    },
    //获取表格数据
    async getTableList(val) {
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        deviceUDID: '',
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        type: this.formInline.info,
        status: this.formInline.status,
        searchKey: val?val.deviceName:this.formInline.value1,
        searchValue: val?val.InputText:this.formInline.searchContent
      }
      console.log(params, '输入列表参数---')

      const res = await this.$axios.post(
        '/httpServe/deviceHistory/input/getDataInfo',
        // '/httpServe/deviceHistory/chats/getDataInfo',
        params,
        true
      )
      console.log(res, '输入列表数据---')
      this.tableData = res.data.content
      this.currentPage = res.data.pages
      this.page.total = res.data.total
    },
    //查询
    async onSubmit(val) {
      if (this.formInline.searchContent.length > 0) {
        this.page.currentPage = 1
      }
      if (this.$route.query.activeName == true) {
        this.formInline.optionDate1 = this.$route.query.starttime.split(' ')[0]
        this.formInline.optionDate2 = this.$route.query.dataDay.split(' ')[0]
        this.formInline.value1 = 'words'
        this.formInline.searchContent = this.$route.query.name
      }
      this.selectVal = val
      this.page.currentPage = 1
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        deviceUDID: '',
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        type: this.formInline.info,
        status: this.formInline.status,
        searchKey: val.deviceName,
        searchValue: val.InputText
      }
      console.log(params, '输入信息列表参数---')
      const res = await this.$axios.post(
        '/httpServe/deviceHistory/input/getDataInfo',
        params,
        true
      )
      console.log(res, '输入列表数据---')
      this.tableData = res.data.content
      this.page.total = res.data.total
    },
    async outReport() {
      let token2 = sessionStorage.getItem('Authorization')
        ? sessionStorage.getItem('Authorization')
        : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A'
      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      this.userId = JSON.parse(userString).userId
      var params = {
        currentPage: this.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        type: this.formInline.info,
        status: this.formInline.status,
        isSend: this.formInline.send,
        searchKey: this.selectVal?this.selectVal.deviceName:this.formInline.value1,
        searchValue: this.selectVal?this.selectVal.InputText:this.formInline.searchContent,
        exportField: []
      }
      const res = await this.$axios.postExport(
        '/httpExport/deviceHistory/input/exportExcel',
        // '/httpServe/deviceHistory/chats/exportExcel123',
        params,
        false,
        this.userId
      )
      console.log(res, '输入导出---')
      const link = document.createElement('a')
      console.info(new Date())

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = ' Input_' + Date.parse(new Date()) + '.xls'
      // console.log(fileName);
      let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    changeType() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  margin: 0 0 10px 12px;
}
.chat {
  padding-left: 10px;
}
</style>
