<template>
  <div class="phone">
    <el-row :gutter="4" class="mt15" style="display: flex; margin-left: 0px">
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          style="width: 100%"
          size="small"
          v-model="formInline.optionDate1"
          type="date"
          :placeholder="$t('DeviceManage.Auditing.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          style="width: 100%"
          size="small"
          v-model="formInline.optionDate2"
          type="date"
          :placeholder="$t('DeviceManage.Auditing.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <!-- 通话类型 下拉控件-->
      <el-col :span="3">
        <el-select
          style="width: 100%"
          class="el-select1"
          v-model="formInline.value"
          :placeholder="$t('DeviceManage.Auditing.Pleaseselect')"
          size="small"
        >
          <el-option
            v-for="item in callType"
            :key="item.value"
            :label="$t(`DeviceManage.Auditing.network.callType.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <select-input
        :options="searchType"
        @selectSearchChange="selectSearchChange"
        @searchList="onSubmit"
      ></select-input>
      <!-- <el-col :span="3">
        <el-select style="width: 100%" class="el-select1" v-model="formInline.value1" placeholder="请选择" size="small" @change="searchTypeChange">
          <el-option v-for="item in searchType" :key="item.value1" :label="item.label1" :value="item.value1">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-input size="small" v-model.trim="formInline.searchContent" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit('ser')"></el-input>
      </el-col> -->
      <el-col :span="3">
        <!-- <el-button size="small" type="primary" @click="onSubmit('ser')">
          查询
        </el-button> -->

        <el-button size="small" type="primary" @click="outReport">
          {{ $t("public.Derive") }}
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="position: relative; margin-left: 2px; margin-right: 10px">
      <el-table
        size="mini"
        :data="tableData"
        stripe
        fit
        tooltip-effect="dark"
        class="sort_table"
        style="width: 100%"
        @sort-change="sort"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          :label="$t('public.DeviceName')"
          prop="contact"
        >
          <template v-slot="{ row }">
            <span v-if="row.devicename == '' || row.devicename == undefined">
              {{ $t("DeviceManage.Auditing.Phone.Unknown") }}
            </span>
            <span v-else>
              {{ row.devicename }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          :label="$t('public.Username')"
          prop="contact"
        >
          <template v-slot="{ row }">
            <span v-if="row.loginname == '' || row.loginname == undefined">
              {{ $t("DeviceManage.Auditing.Phone.Unknown") }}
            </span>
            <span v-else>
              {{ row.loginname }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          :label="$t('public.Section')"
          prop="groupfullname"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{
              row.groupfullname.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          :label="$t('DeviceManage.Auditing.network.Website')"
          prop="url"
        >
          <template v-slot="{ row }">
            <span v-if="row.url == '' || row.url == undefined">
              {{ $t("DeviceManage.Auditing.Phone.Unknown") }}
            </span>
            <span v-else>
              {{ row.url }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          :label="$t('DeviceManage.Auditing.network.Title')"
          prop="title"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          :label="$t('DeviceManage.Auditing.network.Accessstatus')"
          prop="type"
        >
          <template v-slot="{ row }">
            <span v-if="row.type == '0'">
              {{ $t("DeviceManage.Auditing.network.Permit") }}
            </span>
            <span v-if="row.type == '1'">
              {{ $t("DeviceManage.Auditing.network.Prohibit") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          :label="$t('DeviceManage.Auditing.network.Time')"
          prop="createtime"
        >
        </el-table-column>
      </el-table>
      <div style="margin-left: 5px; margin-right: 15px">
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
      </div>
    </el-row>
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue"; //分页
import selectInput from "@/components/selectInput";
const Base64 = require("js-base64").Base64;
export default {
  components: {
    pagination,
    selectInput,
  },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      // duration:"23758490050",
      pickerOptions1: {},
      pickerOptions0: {},
      // 表单控件
      formInline: {
        optionDate1: "", // 选择开始日期
        optionDate2: "", // 选择结束日期
        value: "", //默认选中通话类型
        value1: "deviceName", //默认选中设备名称
        searchContent: "", // 查询关键字
        // sortStr: 'createTime desc' //排序
        status: "", //聊天状态
      },
      tableData: [], //表格数据
      // 分页
      currentPage: "",
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1,
        orderColume: "createTime", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      //访问状态
      callType: [
        {
          label: "访问状态",
          value: "",
        },
        {
          label: "允许",
          value: "0",
        },
        {
          label: "禁止",
          value: "1",
        },
      ],
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      //查询类别
      searchType: [
        {
          value: "deviceName",
          label: "设备名称",
        },
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "url",
          label: "网址",
        },
      ],
      placeholder: "设备名称",
    };
  },
  created() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    if (this.$route.query.activeName == false) {
      this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
      this.formInline.value = "1";
      this.formInline.value1 = "url";
      this.formInline.searchContent = this.$route.query.name;
    } else {
      this.formInline.optionDate1 = this.$common.getAnyMonth("-", -1);
      this.formInline.optionDate2 = this.$common.getDate();
      this.getTableList(this.selectVal); //获取表格数据
    }
  },
  methods: {
    selectSearchChange() {},
    //时长格式
    SecondToDate(t) {
      return _SecondToDate(t);
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //日期控件
    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime();
      let end = new Date(this.formInline.optionDate2).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 || time.getTime() > Date.now()
          );
        },
      };
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.getTableList(this.selectVal);
    },
    //排序
    //排序
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    //下拉选中查询类型--转译input中placeholder显示
    searchTypeChange(item) {
      this.searchType.forEach((k) => {
        if (item == k.value1) {
          this.placeholder = k.label1;
        }
      });
    },
    //获取表格数据
    async getTableList(val) {
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        status: this.formInline.status,
        searchKey: val ? val.deviceName : this.formInline.value1,
        searchValue: val ? val.InputText : this.formInline.searchContent,
      };
      console.log(params, "网络列表参数---");
      const res = await this.$axios.post(
        "/httpServe/deviceHistory/browser/getDataInfo",
        params,
        true
      );
      console.log(res.data, "网络列表数据---");
      this.tableData = res.data.content;
      this.currentPage = res.data.pages;
      this.page.total = res.data.total;
    },
    //查询
    async onSubmit(val) {
      if (this.$route.query.activeName == false) {
        this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
        this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
        this.formInline.value1 = "url";
        this.formInline.value = "1";
        this.formInline.searchContent = this.$route.query.name;
      }
      this.selectVal = val;
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        status: this.formInline.value,
        searchKey: val.deviceName,
        searchValue: val.InputText,
      };
      console.log(params, "网络列表参数---");
      const res = await this.$axios.post(
        "/httpServe/deviceHistory/browser/getDataInfo",
        params,
        true
      );

      console.log(res, "网络列表数据---");
      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    //导出
    async outReport() {
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A";
      let userString = decodeURIComponent(escape(window.atob(token2.split(".")[1])));
      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;
      // console.log('导出')
      var params = {
        currentPage: this.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        deviceUDID: "",
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        status: this.formInline.status,
        searchKey: this.selectVal ? this.selectVal.deviceName : this.formInline.value1,
        searchValue: this.selectVal
          ? this.selectVal.InputText
          : this.formInline.searchContent,
        exportField: [],
      };

      console.log(params, "网络列表导出参数---");

      const res = await this.$axios.postExport(
        "/httpExport/deviceHistory/browser/exportExcel",
        // '/httpServe/deviceHistory/browser/exportExcel',
        // deviceHistory/telephone/exportExcel
        // '/httpServe/deviceHistory/telephone/exportExcel',
        params,
        false,
        this.userId
      );

      console.log(res, "网络列表导出---");
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "Browser_" + Date.parse(new Date()) + ".xls";
      // console.log(fileName);
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    changeType() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  margin: 0 0 10px 12px;
}
.phone {
  padding-left: 10px;
}
</style>
